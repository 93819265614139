@tailwind base;
@tailwind components;
@tailwind utilities;



/* ////////////////////////////////////// */
.footer{
    /* /assets/images/shape/footer-software-shape.png */
    background: url('/public/assets/images/shape/footer-software-shape.png');
    @apply bg-blue-950
}
/* ////////////////////////////////////////////////// */

.navbar__container {
    @apply relative z-50 h-[150px] 2xl:h-[130px] bg-gray-100 flex justify-between items-center pt-6;
  }
  .navbar__items {
    @apply text-xl text-white tracking-wide font-bold;
  }
  .navbar__menu {
    transition: 0.3s ease-in;
    @apply flex gap-4 w-full justify-around
  }
  .navbar__logo {
    @apply text-white text-3xl border-2 rounded-md p-1;
  }
  .navbar__icons {
    @apply text-white text-3xl opacity-0;
  }
  .navbar__items {
    transition: 0.1s ease-in;
    @apply text-xl px-4 py-1 hover:bg-blue-700 text-white tracking-wide font-bold;
  }

  @media (max-width: 1020px) {
    .navbar__icons {
      @apply text-3xl opacity-100 text-blue-700;
    }
    .navbar__menu {
      /* z-index: 999; */
      transition: 0.3s ease-in;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      @apply flex z-10 flex-col absolute top-0 mt-[127px] left-0 h-[55vh] bg-gray-100 px-4 w-full;
    }
    .navbar__menu.navbar__active {
      clip-path: polygon(0 0, 100% 0, 55% 0, 51% 0);
      @apply absolute  opacity-0;
    }
    .navbar__items {
      transition: 0.1s ease-in;
      @apply text-xl py-1 hover:bg-blue-700 text-white tracking-wide font-bold;
    }
  }
  